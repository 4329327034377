import React from 'react';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { stripProtocol } from '../../../../../misc/index';
import HighLightListItem from './styled/HighLightListItem';
import TranslationStore from '../../../../../stores/TranslationStore'
import MediaImage from '../../../MediaImage/index.p';

const HighlightMenuItem = inject('translationStore')(observer(({ menuItem, className, translationStore, data}) => {
  const mediaImage = menuItem.field_media;
  const settings = {
    height: 204,
    width: 289,
    crop: 'fill',
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <HighLightListItem className={className}>
      <Link
        to={stripProtocol(getNested(() => menuItem.uri, "/"))}
        target={menuItem.uri.includes("instagram") ? "_blank" : ""}
      >
        <h4>{menuItem.title}</h4>

        {mediaImage && (
          <figure>
            <div className="image-wrapper">
              <MediaImage
                data={data}
                imageType="HighlightMenuItem"
                mediaImage={mediaImage}
                settings={settings}
                sources={sources}
                ImageWrapper
              />
            </div>
            <figcaption>{menuItem.field_text}</figcaption>
          </figure>
        )}

        <span>
          {getNested(
            () => menuItem.field_link_text,
            translationStore.translate("Discover more")
          )}
        </span>
      </Link>
    </HighLightListItem>
  );
}));

HighlightMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title:PropTypes.string.isRequired,
    uri: PropTypes.string,
    external: PropTypes.bool.isRequired,
    extra_margin: PropTypes.bool,
    parent_style: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  translationStore: PropTypes.instanceOf(TranslationStore),
};
HighlightMenuItem.defaultProps = {
  className: '',
};

export default HighlightMenuItem;
